<template>
  <div class="practice-wrap">
    <div class="banner"></div>
    <div class="header">
      <h1>{{ paperInfo.exam.examName }}</h1>
    </div>
    <a-spin :spinning="spinning">
      <div class="content-wrap">
        <div class="cards">
          <div class="card left">
            <div class="number">
              <div class="top">
                <strong style="color: #2ec45a">{{
                  dataInfo.questionNum - dataInfo.wrongNum
                }}</strong>
                <p>{{ $t("practice.correct_quesition") }}</p>
              </div>
              <div class="bottom">
                <strong style="color: #e82f2e">{{ dataInfo.wrongNum }}</strong>
                <p>{{ $t("practice.wrong_quesition") }}</p>
              </div>
            </div>
            <div class="progress">
              <a-progress
                type="circle"
                strokeColor="#2ec45a"
                :width="150"
                :strokeWidth="12"
                :percent="dataInfo.rightRate.toFixed(0)"
              >
                <template #format="percent">
                  <div class="progress-explain">
                    <span>{{ percent }}%</span>
                    <p>{{ $t("Lab_Lesson_CorrectRate") }}</p>
                  </div>
                </template>
              </a-progress>
            </div>
          </div>
          <div class="card right">
            <div class="number center">
              <strong>{{ formatSeconds(dataInfo.useTime) }}</strong>
              <p>{{ $t("practice.use_time") }}</p>
            </div>
            <div class="diamonds">
              <img src="@/assets/image/practice/diamonds.png" alt="" />
            </div>
          </div>
        </div>
        <div class="btns">
          <a-button style="margin-right: 24px" @click="back">
            {{ $t("go_back") }}
          </a-button>
          <a-button type="primary" @click="again">
            {{ $t("practice.practice_again") }}
          </a-button>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getTaskDetail } from "@/api/project";
import { practiceResult } from "@/api/practice";
import { formatSeconds } from "@/utils/tools";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      spinning: true,
      submitId: Number(route.query.submitId || 0),
      taskId: Number(route.query.taskId || 0),
      detailId: Number(route.query.detailId || 0),
      paperInfo: { exam: {} },
      dataInfo: {
        questionNum: 0,
        wrongNum: 0,
        rightRate: 0,
        useTime: 0,
      },
    });

    getTaskDetail({
      id: state.taskId,
      did: state.detailId,
    }).then((res) => {
      state.paperInfo = res.data;
    });

    const back = () => {
      router.go(-1);
    };

    const again = () => {
      router.replace({
        path: "/exam/practice/paper",
        query: {
          taskId: state.taskId,
          detailId: state.detailId,
        },
      });
    };

    const getDetail = () => {
      state.spinning = true;
      practiceResult(state.submitId).then((res) => {
        state.spinning = false;
        if (res.ret === 0) {
          state.dataInfo = res.data;
        }
      });
    };
    getDetail();

    return {
      formatSeconds,
      ...toRefs(state),
      back,
      again,
    };
  },
};
</script>

<style lang="less" scoped>
.practice-wrap {
  .mixinWrap();
  padding: 24px 0;
}
.banner {
  width: 100%;
  height: 250px;
  background-image: url("~@/assets/image/practice/exam-banner.png");
  background-size: 100% 100%;
  border-radius: 8px 8px 0 0;
  background-color: #fff;
}
.header {
  padding: 24px;
  background-color: #fff;
  h1 {
    margin-bottom: 0;
    line-height: 26px;
    font-size: 18px;
  }
}
.content-wrap {
  padding: 0 24px 100px;
  background-color: #fff;
  .cards {
    .mixinFlex(space-between);
    .card {
      width: 566px;
      height: 214px;
      border-radius: 4px;
      padding: 32px 24px;
      .mixinFlex(space-between; center);
      &.left {
        background: linear-gradient(
          123.08deg,
          rgba(207, 253, 255, 1) 0%,
          rgba(212, 255, 215, 1) 100%
        );
      }
      &.right {
        background: linear-gradient(
          135deg,
          rgba(237, 220, 242, 1) 0%,
          rgba(245, 243, 223, 1) 100%
        );
      }
      .number {
        color: #333;
        height: 100%;
        .mixinFlex(space-between;);
        flex-direction: column;
        &.center {
          justify-content: center;
        }
        strong {
          font-size: 32px;
          font-weight: 600;
          line-height: 1;
        }
        p {
          font-size: 16px;
          line-height: 26px;
          margin: 6px 0 0;
          span {
            color: #666;
          }
        }
      }
      .progress-explain {
        text-align: center;
        padding-top: 6px;
        span {
          font-size: 38px;
          font-weight: 700;
          line-height: 40px;
          display: inline-block;
          height: 40px;
          color: #2ec45a;
        }
        p {
          font-size: 14px;
          line-height: 22px;
          margin: 4px 0 0;
          color: #666;
        }
      }
      .diamonds {
        .mixinImgWrap(171px; 138px);
      }
    }
  }
  .btns {
    text-align: center;
    margin-top: 100px;
  }
}
</style>
